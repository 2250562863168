import { render, staticRenderFns } from "./UrlTabContainer.vue?vue&type=template&id=413b10ec"
import script from "./UrlTabContainer.vue?vue&type=script&setup=true&lang=ts"
export * from "./UrlTabContainer.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TabBox: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/TabBox/TabBox.vue').default})
